import React, { useEffect, useState } from "react";
import { Button, Grid, Box } from "@mui/material";
import { Table } from "react-bootstrap";

function MRPForm() {
  const [pcinput1, setPCInput1] = useState();
  const [rateinput1, setRateInput1] = useState();
  const [totalinput1, setTotalInput1] = useState();

  const [gstrate, setGSTRate] = useState();
  const [percentage, setPercentage] = useState();

  const rateinputchange = (e) => {
    setRateInput1(e.target.value);

    const ratevalue = e.target.value;

    let total = parseInt(pcinput1) * parseInt(ratevalue);
    setTotalInput1(total);
    console.log(total);
  };

  const [listdata, setListData] = useState([]);

  const List = [
    {
      id: "1",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "2",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "3",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "4",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "5",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "6",
      pc: "pc",
      pcvalue: "",
      totalvalue: "",
      ratevalue: "",
      rate: "rate",
    },
    {
      id: "7",
      pc: "pc",
      pcvalue: "",
      totalvalue: "",
      ratevalue: "",
      rate: "rate",
    },
    {
      id: "8",
      pc: "pc",
      pcvalue: "",
      totalvalue: "",
      ratevalue: "",
      rate: "rate",
    },
    {
      id: "9",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "10",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "11",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "12",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "13",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "14",
      pc: "pc",

      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
    {
      id: "15",
      pc: "pc",
      pcvalue: "",
      ratevalue: "",
      totalvalue: "",
      rate: "rate",
    },
  ];

  useEffect(() => {
    setListData(List);
  }, []);

  console.log("listdata", listdata);

  const pcratehandlechange = (e) => {
    console.log(e.target.value);

    const prevvalue = e.target.value;

    console.log({ ...prevvalue, [e.target.name]: e.target.value });
  };

  const totalvalueamount = listdata
    .map((val) => val.totalvalue)
    .reduce((a, b) => Number(a) + Number(b), 0);

  console.log("tpcvalue", totalvalueamount);
  return (
    <div className="">
      <Grid
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <Grid item xs={12} sm={6} md={6} lg={12}>
          <div style={{ marginTop: "25px" }}>
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "#20c997",
                }}
              >
                MRP Form
              </span>
            </div>
            <table className="table p-0">
              <thead>
                <tr>
                  <th style={{ width: "90px" }}>PC</th>
                  <th style={{ width: "90px" }}>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {listdata.map((val, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        className="form-control shadow-none"
                        type="number"
                        name={`${val.pc}${index}`}
                        id=""
                        /*   onChange={pcratehandlechange} */

                        onChange={(e) => {
                          setListData((prev) => {
                            let temp = [...prev];
                            temp[index].pcvalue = e.target.value;
                            return temp;
                          });

                          setListData((prev) => {
                            let temp = [...prev];
                            temp[index].totalvalue =
                              e.target.value * val.ratevalue;
                            return temp;
                          });
                        }}
                        style={{ width: "90px" }}
                      />{" "}
                    </td>
                    <td>
                      {" "}
                      <input
                        style={{ width: "90px" }}
                        type="number"
                        className="form-control shadow-none"
                        name={`${val.rate}${index}`}
                        id=""
                        /*    onChange={pcratehandlechange} */

                        onChange={(e) => {
                          setListData((prev) => {
                            let temp = [...prev];
                            temp[index].ratevalue = e.target.value;
                            return temp;
                          });

                          setListData((prev) => {
                            let temp = [...prev];
                            temp[index].totalvalue =
                              e.target.value * val.pcvalue;
                            return temp;
                          });
                        }}
                      />{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "20px",
                          color: "crimson",
                        }}
                      >
                        {val.ratevalue && val.pcvalue
                          ? (val.pcvalue * val.ratevalue).toFixed(2)
                          : null}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr
                  style={{
                    textAlign: "right",
                  }}
                >
                  <td style={{ color: "blue" }}>
                    {listdata?.filter((val) => val.totalvalue !== "").length}{" "}
                    <span>Items</span>
                  </td>
                  <td>
                    <span style={{ fontSize: "20px" }}>Total</span>
                  </td>
                  <td style={{ fontSize: "23px" }}>
                    Rs. {totalvalueamount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div className="form-group d-flex">
                      <span style={{ marginRight: "35px" }}>Less (%)</span>
                      <input
                        type="number"
                        name=""
                        className="form-control shadow-none"
                        id=""
                        style={{
                          width: "100px",
                          marginLeft: "15px",
                          fontSize: "19px",
                        }}
                        onChange={(e) => setPercentage(e.target.value)}
                      />
                    </div>
                  </td>
                  <td>
                    {" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        paddingRight: "10px",
                      }}
                    >
                      {" "}
                      (-)
                    </span>
                    <span style={{ fontSize: "22px" }}>
                      Rs.{" "}
                      {percentage ? (totalvalueamount * percentage) / 100 : "0"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Balance{" "}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Rs.{" "}
                        {percentage
                          ? totalvalueamount -
                            (totalvalueamount * percentage) / 100
                          : "0"}
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default MRPForm;
