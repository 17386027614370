import React, { useEffect, useState } from "react";
import { Button, Grid, Box } from "@mui/material";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";

function CountDashboard() {
  const navigate = useNavigate();
  const [pcinput1, setPCInput1] = useState();
  const [rateinput1, setRateInput1] = useState();
  const [totalinput1, setTotalInput1] = useState();

  const [gstrate, setGSTRate] = useState();

  const rateinputchange = (e) => {
    setRateInput1(e.target.value);

    const ratevalue = e.target.value;

    let total = parseInt(pcinput1) * parseInt(ratevalue);
    setTotalInput1(total);
    console.log(total);
  };

  const location = useLocation();
  const finalpath = location.pathname.substring(1);

  console.log("path", finalpath);

  return (
    <div className="" style={{ marginTop: "30px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button variant="contained" onClick={() => window.location.reload()}>
          <CachedIcon style={{ fontSize: "30px" }} />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <h3>AA Marketing</h3>

        <span>Uppuguda, Lalithabagh</span>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          style={{
            background: finalpath === "beforegst" ? "#2196f3" : "grey",
          }}
          onClick={() => navigate("/beforegst")}
        >
          Before GST
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate("/aftergst")}
          style={{
            background: finalpath === "aftergst" ? "yellowgreen" : "grey",
          }}
        >
          After GST
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate("/mrpform")}
          style={{ background: finalpath === "mrpform" ? "orange" : "grey" }}
        >
          MRP Form
        </Button>
      </div>
    </div>
  );
}

export default CountDashboard;
