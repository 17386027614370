import logo from "./logo.svg";
import "./App.css";
import CountDashboard from "./count/CountDashboard";
import { Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BeforeGst from "./count/BeforeGst";
import AfterGST from "./count/AfterGST";
import MRPForm from "./count/MRPform";

function App() {
  return (
    <Container maxWidth="sm">
      <Router>
        <CountDashboard />
        <Routes>
          <Route path="/beforegst" element={<BeforeGst />} />
          <Route path="/aftergst" element={<AfterGST />} />
          <Route path="/mrpform" element={<MRPForm />} />
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
